<template>
  <div class="shop-order">
    <el-card class="top-card">
      <div class="flex-center-between wallet-info">
        <div class="flex-center-start">
          <div class="flex-center-start box">
            <div class="icon">
              <img :src="walletUrl" alt="" />
            </div>
            <div>
              <div class="count">${{ userMoney.toFixed(2) || '0.00' }}</div>
              <div class="label">{{ $t('qian-bao-yu-e') }}</div>
            </div>
          </div>
          <div class="flex-center">
            <div class="icon">
              <img :src="totalUrl" alt="" />
            </div>
            <div>
              <div class="count">${{ totalProfit || '0.00' }}</div>
              <div class="label">{{ $t('lei-ji-shou-yi') }}</div>
            </div>
          </div>
        </div>
        <div class="flex-center">
          <el-button type="primary" @click="toPath('recharge')">{{
            $t('chong-zhi')
          }}</el-button>
          <el-button type="primary" @click="toPath('withdraw')">{{
            $t('ti-xian')
          }}</el-button>
          <el-button type="primary" plain>{{ $t('xin-dai-fu-wu') }}</el-button>
        </div>
      </div>
    </el-card>
    <el-card class="main-card table-page">
      <div class="main-table">
        <div class="table-btns">
          <el-tag
            effect="plain"
            :type="tableIndex == 1 ? '' : 'info'"
            @click="changeTable(1)"
            >{{ $t('chong-zhi') }}</el-tag
          >
          <el-tag
            effect="plain"
            :type="tableIndex == 2 ? '' : 'info'"
            @click="changeTable(2)"
            >{{ $t('ti-xian') }}</el-tag
          >
        </div>
        <div class="table-container">
          <el-table
            :data="tableData"
            border
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              :label="$t('ri-qi')"
              prop="CreatedAt"
            ></el-table-column>
            <el-table-column
              :label="$t('ding-dan-hao-0')"
              prop="date"
            ></el-table-column>
            <el-table-column
              :label="
                tableIndex === 1
                  ? $t('chong-zhi-shu-liang')
                  : $t('ti-xian-jin-e')
              "
              prop="Amount"
            ></el-table-column>
            <el-table-column
              :label="$t('bi-zhong-xie-yi')"
              prop="date"
            ></el-table-column>
            <el-table-column :label="$t('ding-dan-zhuang-tai')" prop="Status">
              <template slot-scope="scope">
                <div>{{ getStatusName(scope.row.Status) }}</div>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('zhi-fu-ping-zheng')"
              prop="PaymentVoucher"
            >
              <template slot-scope="scope">
                <img
                  :src="scope.row.PaymentVoucher"
                  v-if="scope.row.PaymentVoucher"
                  alt=""
                /> </template
            ></el-table-column>
            <el-table-column
              :label="$t('shi-ji-dao-zhang')"
              prop="Amount"
            ></el-table-column>
            <el-table-column
              :label="
                tableIndex === 1 ? $t('chong-zhi-di-zhi') : $t('ti-xian-di-zhi')
              "
              prop="date"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="bottom-page flex-center">
        <el-pagination
          background
          @size-change="sizeChange"
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { getWalletInfo, rechargeLog, withdrawLog } from '@/api/user'
export default {
  data() {
    return {
      tableData: [],
      walletUrl: require('@/assets/imgs/wallet.png'),
      totalUrl: require('@/assets/imgs/total.png'),
      selectRows: [],
      page: {
        size: 10,
        current: 1,
        total: 123
      },
      userMoney: 0,
      totalProfit: '',
      tableIndex: 1
    }
  },
  mounted() {
    this.init()
    this.initTable()
  },
  methods: {
    init() {
      getWalletInfo().then((res) => {
        let data = res.data.Items[0]
        this.userMoney = data.Balance
      })
    },
    initTable() {
      let param = {
        current: this.page.current,
        pageSize: this.page.size
      }
      if (this.tableIndex == 1) {
        rechargeLog(param).then((res) => {
          this.tableData = res.data.Items
          this.page.total = res.data.Pagination.totalRecords
        })
      } else {
        withdrawLog(param).then((res) => {
          this.tableData = res.data.Items
          this.page.total = res.data.Pagination.totalRecords
        })
      }
    },
    handleSelectionChange(val) {
      this.selectRows = val
    },
    sizeChange(size) {
      this.page.size = size
      this.initTable()
    },
    currentChange(page) {
      this.page.current = page
      this.initTable()
    },
    changeTable(index) {
      this.tableIndex = index
      this.initTable()
    },
    toPath(path) {
      this.$router.push(path)
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-shen-he')
          break
        case '1':
          name = this.$t('yi-tong-guo')
          break
        case '2':
          name = this.$t('yi-ju-jue')
          break
      }
      return name
    }
  }
}
</script>